.button {
  background: #fcb500;
  border-radius: 7px;
  padding: 16px 32px;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  border: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
