.product-feature {
  color: #fff;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-bottom: 45px;
    user-select: none;
    max-width: 100%;

    @media screen and (max-width: 890px) {
      margin-bottom: 15px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__text_accentuated {
    color: #fcb500;
  }

  &:nth-child(2) {
    .product-feature {
      &__text {
        font-size: 36px;
        line-height: 43px;
      }

      &__text_accentuated {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }
}
