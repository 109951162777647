@import '../../global/styles-vars.scss';

.discount {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $WidthContainer;
  color: #000;
  position: relative;
  box-sizing: border-box;

  @media screen and (max-width: ($WidthContainer + 50px)) {
    width: 100%;
  }

  @media screen and (max-width: 890px) {
    padding: 0 20px;
  }

  &__title,
  &__title_accentuated {
    margin-top: 110px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    color: #fff;
    text-align: center;

    @media screen and (max-width: 820px) {
      font-size: 40px;
      line-height: 49px;
    }

    @media screen and (max-width: 690px) {
      font-size: 30px;
      line-height: 39px;
    }
  }

  &__title_accentuated {
    color: #fcb500;
  }

  &__result {
    display: flex;
    align-items: center;
    margin: 85px 0 50px;
    font-weight: 400;

    @media screen and (max-width: 890px) {
      margin: 55px 0 45px;
    }
  }

  &__price-before {
    font-size: 36px;
    line-height: 43px;
    color: #adadad;
    padding: 0 16px;
    text-decoration: line-through;
  }

  &__price-after {
    font-size: 48px;
    line-height: 57px;
    color: #fff;
    padding: 0 16px;
  }

  &__details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: 890px) {
      grid-template-columns: 1fr;
      row-gap: 50px;
    }
  }

  &__button {
    margin: 64px 0 112px;
  }
}
