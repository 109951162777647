@import '../../global/styles-vars.scss';

.banner {
  width: 100%;
  height: 792px;
  background-image: url('./img/bg.png');
  background-size: cover;
  background-position: 0 -12px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    width: $WidthContainer;
    color: #000;
    position: relative;

    @media screen and (max-width: ($WidthContainer + 50px)) {
      width: 100%;
    }
  }

  &__title {
    margin-top: 96px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 144px;
    line-height: 152px;
    color: #fff;

    @media screen and (max-width: 1330px) {
      font-size: 130px;
      line-height: 138px;
    }

    @media screen and (max-width: 1250px) {
      font-size: 110px;
      line-height: 118px;
    }

    @media screen and (max-width: 1010px) {
      font-size: 90px;
      line-height: 98px;
    }

    @media screen and (max-width: 625px) {
      font-size: 70px;
      line-height: 78px;
    }

    @media screen and (max-width: 520px) {
      font-size: 50px;
      line-height: 58px;
    }

    @media screen and (max-width: 400px) {
      font-size: 45px;
      line-height: 53px;
    }

    @media screen and (max-width: 350px) {
      font-size: 35px;
      line-height: 33px;
    }
  }

  &__text {
    margin-top: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    max-width: 640px;

    @media screen and (max-width: 940px) {
      max-width: 495px;
    }
  }

  &__sub-title {
    margin-top: 120px;
    font-weight: 700;
    font-size: 36px;
    line-height: 43px;

    @media screen and (max-width: 625px) {
      margin-top: 70px;
    }
  }

  &__sub-text {
    margin-top: 16px;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    max-width: 340px;

    @media screen and (max-width: 940px) {
      max-width: 260px;
    }
  }

  &__title,
  &__text,
  &__sub-title,
  &__sub-text {
    @media screen and (max-width: ($WidthContainer + 50px)) {
      padding: 0 30px;
    }
  }

  &__image {
    width: 820px;
    height: 980px;
    position: absolute;
    top: -75px;
    right: -85px;
    user-select: none;
    pointer-events: none;
    object-fit: cover;

    @media screen and (max-width: 1480px) {
      right: 0;
    }

    @media screen and (max-width: 1330px) {
      width: 755px;
      height: 900px;
      top: 0;
    }

    @media screen and (max-width: 1145px) {
      width: 640px;
      top: 45px;
    }

    @media screen and (max-width: 1045px) {
      width: 610px;
      height: 835px;
      top: 90px;
    }

    @media screen and (max-width: 900px) {
      width: 520px;
      height: 710px;
      top: 175px;
    }

    @media screen and (max-width: 800px) {
      width: 440px;
      height: 540px;
      top: 345px;
    }

    @media screen and (max-width: 625px) {
      top: 345px;
    }

    @media screen and (max-width: 600px) {
      width: 380px;
      height: 450px;
      top: 430px;
    }

    @media screen and (max-width: 490px) {
      width: 350px;
      height: 425px;
      top: 470px;
    }

    @media screen and (max-width: 375px) {
      width: 280px;
      height: 360px;
      top: 530px;
    }
  }
}
